import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IResourcePlannerTotalResult } from 'src/apis/types/resourcePlannerAPI';
import { concatQuerys } from 'src/utils/url';

const GROUP_BY_RESOURCE_TOTAL_URL_PATH = '/api/v2/resource-planner/group-by-employee-total-row';
export const GROUP_BY_RESOURCE_TOTAL_MSW_STATUS_KEY = 'GetGroupByResourceTotalAPI';

export const GROUP_BY_RESOURCE_TOTAL_KEY = 'GROUP_BY_RESOURCE_TOTAL';

const resourcePlannerFetch = (
  resourceIds: string,
  params: { [key: string]: string },
): Promise<IResourcePlannerTotalResult> =>
  getFetch({
    path: `${GROUP_BY_RESOURCE_TOTAL_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value:
          // we need the hyphens in dates
          key === 'period-starts-at' || key === 'period-ends-at'
            ? params[key]
            : params[key].replaceAll('-', ''),
      })),
    )}`.concat(`&resourceIds=${resourceIds}`),
    key: GROUP_BY_RESOURCE_TOTAL_MSW_STATUS_KEY,
  });

export const useGetGroupByResourceTotal2 = (
  filteredResourceIds: string[],
  params?: { [key: string]: string },
) => {
  const { data, isError, ...restProps } = useQuery(
    [GROUP_BY_RESOURCE_TOTAL_KEY, filteredResourceIds, params || {}],
    () => resourcePlannerFetch(filteredResourceIds.toString(), params || {}),
    {
      enabled:
        filteredResourceIds != null &&
        filteredResourceIds !== undefined &&
        filteredResourceIds.length > 0,
    },
  );

  return {
    data: {
      ...data?.model.properties.row,
      budget: data?.model.properties.row.totalBudget,
      notPlanned: data?.model.properties.row.totalNotPlanned,
    },
    isError,
    ...restProps,
  };
};
