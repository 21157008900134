/* eslint-disable no-console */
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DataGrid } from 'src/components/mui-components/DataGrid';
import {
  GridRowsProp,
  GridColDef,
  DataGridProProps,
  useGridApiRef,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridRenderCellParams,
  GridCellParams,
  GridGroupNode,
  GridEventListener,
  GridRowModelUpdate,
  GridApi,
  GridValueGetterParams,
  GridColumnMenuProps,
  GridColumnMenu,
} from '@mui/x-data-grid-pro';
import { useQueryClient } from '@tanstack/react-query';

import {
  useGetGroupByResource2,
  GROUP_BY_PROJECT_KEY,
  GROUP_BY_RESOURCE_KEY,
} from 'src/apis/resourcePlannerAPI';
import {
  GROUP_BY_RESOURCE_TOTAL_KEY,
  useGetGroupByResourceTotal2,
} from 'src/apis/resourcePlannerAPI/get/getGroupByResourceTotalAPI2';
import { useFilterDispatch, useFilterStore } from 'src/stores/FilterStore';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import {
  getDateStringFromSiteLocale,
  getDateWithZeroOffsetFromDateString,
  getLanguageFromSiteLocale,
} from 'src/utils/date';
import { useGetPartialGroupByResource } from 'src/apis/resourcePlannerAPI/get/getGroupByResourceAPI';
import { UnfoldLess, UnfoldMore } from '@mui/icons-material';

import { Box, IconButton } from 'src/components/mui-components';
import { TFunction, useTranslation } from 'react-i18next';
import { translationAnyText } from 'src/utils/translation';
import { useGetCurrentLanguage } from 'src/apis/userSettingsAPI';
import postResourcePlannerChange from 'src/apis/resourcePlannerAPI/post/postResourcePlannerChange';
import { IResourcePlannerItem } from 'src/apis/types/resourcePlannerAPI';
import { ViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';
import { getExpandedRowIds2, setExpandedRowIds2 } from '../../helper/expandedRow';
import camelToPascal from '../../helper/camelToPascal';
import generatePeriodLabel from '../../helper/generatePeriodLabel';
import { expandRows, collapseRow } from '../../helper/groupingColumnAction';
import { NameTreeColumnRenderer } from '../NameTreeColumnRenderer';
import styles from '../../color/TableColors.module.scss';
import { GeneralColumnRendererMui } from '../GeneralColumnRendererMui';
import TotalColumnRenderer from '../TotalColumnRenderer';
import { getFixedColumnTitleIdentifier } from '../../helper/getFixedColumnTooltip';
import { UnplannedMuiCell } from '../UnplannedMuiCell';
import { RPEmployeeViewDisableExpandAllCount } from '../../constants';
import ExcessiveOrNoData from '../ExcessiveOrNoData';

import {
  RPEmployeeViewInitialExpandedRowsStateKey2,
  RPProjectViewInitialExpandedRowsStateKey2,
  RPSelectedFilterListStateKey,
} from '../../localStorageKeys';
import { IPeriodColumn, RPRow } from '../../types/resourcePlanner';
import { CustomizedCell } from '../CustomizedCell';

interface GroupingCellWithLazyLoadingProps
  extends GridRenderCellParams<any, any, any, GridGroupNode> {
  hideDescendantCount?: boolean;
}

interface ResourceTableGroupedByEmployeeProps {
  selectedViewOptions: ViewOptionsChangeParameters;
}

const updateRows = (apiRef: React.MutableRefObject<GridApi>, rows: GridRowModelUpdate[]) => {
  if (!apiRef.current) {
    return;
  }

  const rowsToAdd = [...rows];
  rows.forEach((row) => {
    if (row.canExpand) {
      // Add a placeholder row to make the row expandable
      rowsToAdd.push({
        id: `placeholder-children-${row.id}`,
        name: [...row.name, ''],
      });
    }
  });
  apiRef.current.updateRows(rowsToAdd);
};

const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row?.name;

const getTranslatedText = (
  t: TFunction<'resourcePlanner', undefined>,
  unitType: string,
  identifier: string,
) => {
  if (identifier === 'budget') {
    return translationAnyText(
      t,
      `ColumnLabelBudget${unitType === 'days' ? 'Days' : 'Hours'}EmployeeView`,
    );
  }
  if (
    identifier === 'totalBooked' ||
    identifier === 'totalActualWorkload' ||
    identifier === 'notPlanned'
  ) {
    return translationAnyText(
      t,
      `ColumnLabel${camelToPascal(identifier)}${unitType === 'days' ? 'Days' : 'Hours'}`,
    );
  }
  return translationAnyText(t, `ColumnLabel${camelToPascal(identifier)}`);
};

const fixedColumns: GridColDef[] = [
  {
    field: 'status',
  },
  {
    field: 'startsAt',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'endsAt',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'budget',
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'totalBooked',
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'totalActualWorkload',
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'notPlanned',
    headerAlign: 'right',
    align: 'right',
    renderCell: UnplannedMuiCell,
    cellClassName: (params: GridCellParams) => {
      const values = params.row?.notPlanned;
      if (values === undefined || values >= 0) {
        return '';
      }
      return styles[`tableCellFontColorAlertDark` as keyof typeof styles];
    },
  },
];

const CustomColumnMenu = (props: GridColumnMenuProps) => (
  <GridColumnMenu
    {...props}
    slots={{
      columnMenuFilterItem: null,
    }}
  />
);

const sharedColPeriodSettings: Omit<GridColDef, 'field'> = {
  align: 'right',
  hideable: false,
  headerAlign: 'right',
  sortable: false,
  minWidth: 120,
};

export const ResourceTableGroupedByEmployeeMui = memo(
  ({ selectedViewOptions }: ResourceTableGroupedByEmployeeProps) => {
    const { t } = useTranslation('resourcePlanner');
    const qc = useQueryClient();

    const { filterQueryObj, isRequiredQuery } = useFilterStore();
    const filterDispatch = useFilterDispatch();
    console.log('[rendered] ResourceTableGroupedByEmployeeMui');
    // Wrap this in useEffect to fix warning described in this stack overflow post:
    // https://stackoverflow.com/questions/62336340/cannot-update-a-component-while-rendering-a-different-component-warning
    useEffect(() => {
      if (isRequiredQuery) {
        if (filterDispatch) filterDispatch({ type: 'DEACTIVATE_RENDER' });
      }
    }, [filterDispatch, isRequiredQuery]);

    // get filter from localStorage and compare with newest filter. If change reset expanded ids
    if (JSON.stringify(filterQueryObj) !== localStorage.getItem(RPSelectedFilterListStateKey)) {
      localStorage.removeItem(RPEmployeeViewInitialExpandedRowsStateKey2);
      localStorage.removeItem(RPProjectViewInitialExpandedRowsStateKey2);
    }
    localStorage.setItem(RPSelectedFilterListStateKey, JSON.stringify(filterQueryObj));

    const { isLoading, isEmpty, periods, children, responseType, resourceIds } =
      useGetGroupByResource2({ selectedFilterList: filterQueryObj }, selectedViewOptions);
    const { data: totalRow } = useGetGroupByResourceTotal2(resourceIds, selectedViewOptions);
    const getRowsByParentId = useGetPartialGroupByResource(
      { selectedFilterList: filterQueryObj },
      selectedViewOptions,
    );

    const { mutate: postChange } = postResourcePlannerChange();

    const initialRows: GridRowsProp = [];

    const [tableRows] = useState(initialRows);
    const unitType = selectedViewOptions['unit-types'];
    const reportingType = selectedViewOptions['reporting-types'];

    const apiRef = useGridApiRef();

    const { currentLanguage } = useGetCurrentLanguage();

    const siteLocale = useGetLocale();
    const siteLanguage = getLanguageFromSiteLocale(siteLocale);

    const convertData = useCallback(
      (rpData: IResourcePlannerItem[], parentName: string[] = []): any => {
        const result = rpData.flatMap((item) => {
          const newRow: any = {
            ...item,
            name: [...parentName, item.name].flat(),
            children: item.children || [],
          };

          if (item?.children && item?.children?.length > 0) {
            return [newRow, ...convertData(item.children, newRow.name)];
          }
          return newRow;
        });
        return result;
      },
      [],
    );

    const getChildren = async (
      data: IResourcePlannerItem[],
      parentPath: string[],
      id: string,
      findChild: boolean,
    ) => {
      const parentPathStr = parentPath.join('-');

      if (!findChild) {
        return convertData(data).filter(
          (row: any) => row.name.slice(0, -1).join('-') === parentPathStr,
        );
      }

      getRowsByParentId.reset();

      const rawChildRowsFromServer = await getRowsByParentId.mutateAsync(id);
      const childRows = rawChildRowsFromServer?.model?.properties?.children || [];

      return convertData(childRows, parentPath).filter(
        (row: any) => row.name.slice(0, -1).join('-') === parentPathStr,
      );
    };

    const fetchRpData = async (
      data: IResourcePlannerItem[] = [],
      id: string = '',
      parentPath: string[] = [],
      findChild: boolean = false,
    ) => {
      const rows = (await getChildren(data, parentPath, id, findChild)).map(
        (row: IResourcePlannerItem) => ({
          ...row,
        }),
      );
      return rows;
    };

    const onCellValueChange = useCallback(
      (row: RPRow, column: IPeriodColumn, value: string) => {
        const cachedData:
          | { model: { properties: { children: IResourcePlannerItem[] } } }
          | undefined = qc.getQueryData([
          GROUP_BY_RESOURCE_KEY,
          selectedViewOptions || {},
          filterQueryObj || {},
        ]);

        if (cachedData) {
          const findRowById = (
            data: IResourcePlannerItem[],
            id: string,
          ): IResourcePlannerItem | null => {
            // eslint-disable-next-line no-restricted-syntax
            for (const item of data) {
              if (item.id === id) {
                return item;
              }

              if (item.children) {
                const found = findRowById(item.children, id);
                if (found) {
                  return found;
                }
              }
            }
            return null;
          };

          const updatedRow = findRowById(cachedData.model.properties.children, row?.id);

          if (!column?.identifier) {
            throw new Error('Could not find updated column');
          }

          if (updatedRow) {
            const updatedCell = updatedRow.values[column?.identifier];
            updatedRow.values[column?.identifier] = {
              ...updatedCell,
              displayValue: value,
            };
          }

          qc.setQueryData(
            [GROUP_BY_RESOURCE_KEY, selectedViewOptions || {}, filterQueryObj || {}],
            cachedData,
          );
        }

        const { resourceId, workItemId } = row;
        const startsAt = column?.startsAt || '';
        const endsAt = column?.endsAt || '';

        postChange(
          {
            resourceId,
            workItemId,
            unitType,
            value,
            startsAt,
            endsAt,
          },
          {
            onSettled: () => {
              qc.invalidateQueries([GROUP_BY_RESOURCE_KEY]);
              qc.invalidateQueries([GROUP_BY_PROJECT_KEY]);
              qc.invalidateQueries([GROUP_BY_RESOURCE_TOTAL_KEY]);
            },
          },
        );
      },
      [filterQueryObj, postChange, qc, selectedViewOptions, unitType],
    );

    const columns: GridColDef[] = useMemo(
      () => [
        ...fixedColumns.map((fixedColumn) => ({
          ...fixedColumn,
          sortable: false,
          description: translationAnyText(t, getFixedColumnTitleIdentifier(fixedColumn.field)),
          headerName: getTranslatedText(t, unitType, fixedColumn.field),
          minWidth: 120,
          valueGetter: (params: GridValueGetterParams) =>
            GeneralColumnRendererMui({
              row: params.row,
              id: params.field,
              value: params.value,
              siteLocale,
            }),
        })),
        ...periods.map((periodColumn) => ({
          headerName: generatePeriodLabel(
            periodColumn.identifier,
            selectedViewOptions['period-types'],
            currentLanguage,
            t,
          ),
          description:
            selectedViewOptions['period-types'] !== 'day'
              ? `${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.startsAt),
                  siteLanguage,
                )} - ${getDateStringFromSiteLocale(
                  getDateWithZeroOffsetFromDateString(periodColumn.endsAt),
                  siteLanguage,
                )}`
              : '',
          field: periodColumn.identifier,
          minWidth: 120,
          renderCell: (params: GridRenderCellParams) => {
            if (params.row?.values) {
              const periodCellData = params.row?.values[periodColumn.identifier];
              const isCellEditable = (params.row?.editable && periodCellData?.editable) || false;

              if (
                !isCellEditable &&
                params.row?.hierarchyType !== 'resource' &&
                (reportingType === 'availability' || unitType === 'percentages') &&
                periodCellData?.displayValue.toString() === '0'
              ) {
                return '';
              }

              if (!params.row?.values[periodColumn.identifier]?.value) {
                return '';
              }
              //   console.log(
              //     'params.row?.values[periodColumn.identifier]?.value',
              //     params.row?.values,
              //   );

              // console.log('periodColumn.identifier', periodColumn);
              return (
                <CustomizedCell
                  row={params.row}
                  column={periodColumn}
                  displayValue={params.row?.values[periodColumn.identifier]?.displayValue}
                  value={params.row?.values[periodColumn.identifier]?.value}
                  isEditable={isCellEditable}
                  onCellValueChange={onCellValueChange}
                  unitType={unitType}
                  reportingType={reportingType}
                />
              );
            }

            return null;
          },
          cellClassName: (params: GridCellParams<any>) => {
            if (params.id === 'total') {
              return '';
            }
            const { values } = params.row || {};
            const heatmapCode = values?.[params.field]?.heatmapCode;
            if (heatmapCode && heatmapCode !== 'bgWhite') {
              return styles[`tableCell_${heatmapCode}` as keyof typeof styles];
            }
            return '';
          },
          ...sharedColPeriodSettings,
        })),
        {
          headerName: t('ColumnLabelTotal'),
          field: 'total',
          sortable: false,
          align: 'right',
          headerAlign: 'right',
          minWidth: 150,
          renderCell: (params: GridRenderCellParams) =>
            TotalColumnRenderer({
              displayValue: params?.row?.total?.displayValue,
              unitType,
            }),
        },
      ],
      [
        periods,
        t,
        unitType,
        siteLocale,
        selectedViewOptions,
        currentLanguage,
        siteLanguage,
        reportingType,
        onCellValueChange,
      ],
    );

    const groupingColumnRender = () => {
      const getShouldDisableExpandAll = () => {
        if (children && children.length >= RPEmployeeViewDisableExpandAllCount) {
          return true;
        }
        return false;
      };

      return {
        renderHeader: () => (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <span>
              <IconButton
                aria-label="expand"
                size="small"
                title={
                  getShouldDisableExpandAll()
                    ? t('ExpandAllDisabledTooltipEmployee', {
                        maxCount: RPEmployeeViewDisableExpandAllCount,
                      })
                    : t('UnfoldMore')
                }
                onClick={() => {
                  expandRows(apiRef);
                }}
                disabled={getShouldDisableExpandAll()}
              >
                <UnfoldMore fontSize="inherit" />
              </IconButton>
            </span>
            <IconButton
              aria-label="collapse"
              size="small"
              title={t('UnfoldLess')}
              onClick={() => {
                collapseRow(apiRef);
              }}
            >
              <UnfoldLess fontSize="inherit" />
            </IconButton>
            <span style={{ marginLeft: 8 }}>Name</span>
          </Box>
        ),
        renderCell: (params: GridRenderCellParams) => {
          if (params.id === 'total') {
            return 'Total';
          }
          return <NameTreeColumnRenderer {...(params as GroupingCellWithLazyLoadingProps)} />;
        },
      };
    };

    const addChildNode = async (parentData: IResourcePlannerItem[]) => {
      const fetchAllData = async (data: IResourcePlannerItem[]) => {
        const promises = data.map(async (item: IResourcePlannerItem) => {
          if (item?.children && item?.children.length > 0) {
            const row = apiRef.current.getRow(item.id);
            const parentName = row?.name || [];
            const childrenRows = await fetchRpData([row], row.id, parentName, false);
            const parentRow = {
              ...item,
              name: parentName,
            };
            const newRows = [
              ...childrenRows,
              { ...parentRow, childrenFetched: item.children && item.canExpand },
              { id: `placeholder-children-${item.id}`, _action: 'delete' },
            ];

            updateRows(apiRef, newRows);

            if (childrenRows?.length > 0) {
              await fetchAllData(item.children);
            }
          }
        });

        await Promise.all(promises);
      };

      fetchAllData(parentData);
    };

    // Need to remove all rows after new data is fetched, otherwise previous data remain
    const removeAllRows = () => {
      const rowIds = apiRef.current.getAllRowIds();
      rowIds.forEach((rowId) => {
        apiRef.current.updateRows([{ id: rowId, _action: 'delete' }]);
      });
    };

    useEffect(() => {
      removeAllRows();
      fetchRpData(children).then((rowsData) => {
        updateRows(apiRef, rowsData);
        if (rowsData.length > 0) {
          // Add child row for row with expanded rowId in localStorage
          addChildNode(rowsData);
        }
      });

      setTimeout(() => {
        apiRef.current.autosizeColumns({
          includeHeaders: true,
          includeOutliers: true,
          expand: true,
        });
      }, 100);

      const handleRowExpansionChange: GridEventListener<'rowExpansionChange'> = async (node) => {
        const row = apiRef.current.getRow(node.id) as any | null;

        // store rowId key in local storage
        const storageKey = RPEmployeeViewInitialExpandedRowsStateKey2;
        const expandedRow = getExpandedRowIds2(storageKey);

        // Check if row.id exists in expandedRow
        if (expandedRow.some((item) => item.originalId === row.id)) {
          // If row.id exists, remove it
          const updatedExpandedRow = expandedRow.filter((item) => item.originalId !== row.id);
          setExpandedRowIds2(storageKey, updatedExpandedRow);
        } else {
          // If row.id does not exist, add it
          const updatedExpandedRow = [...expandedRow, { originalId: row.id }];
          setExpandedRowIds2(storageKey, updatedExpandedRow);
        }

        if (!node.childrenExpanded || !row || row.childrenFetched) {
          return;
        }

        const childrenRows = await fetchRpData(row, row.id, row.name, true);
        const newRows = [
          ...childrenRows,
          { ...row, childrenFetched: true },
          { id: `placeholder-children-${node.id}`, _action: 'delete' },
        ];

        updateRows(apiRef, newRows);

        if (childrenRows?.length >= 1) {
          addChildNode(childrenRows);
        }
      };

      return apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiRef, children]);

    const pinnedRows = useMemo(
      () => ({
        bottom: [
          {
            id: 'total',
            ...totalRow,
          },
        ],
      }),
      [totalRow],
    );

    const isGroupExpandedByDefault = (node: GridGroupNode) => {
      const rows = getExpandedRowIds2(RPEmployeeViewInitialExpandedRowsStateKey2);
      return rows.some((item: { originalId: string }) => node.id === item.originalId);
    };

    // offsetTop of the table
    const [offsetTop, setOffsetTop] = useState(0);
    const dataGridContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const handleResize = () => {
        if (dataGridContainerRef.current) {
          setOffsetTop(dataGridContainerRef.current.offsetTop);
        }
      };

      window.addEventListener('resize', handleResize);

      // Initial height setting
      if (dataGridContainerRef.current) {
        setOffsetTop(dataGridContainerRef.current.offsetTop);
      }

      // Clean up the event listener when the component is unmounted
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    if (responseType === 'ExcessiveData' || (isEmpty && responseType === 'NoData')) {
      return <ExcessiveOrNoData type={responseType} />;
    }

    return (
      <div
        style={{
          width: '100%',
          height: `calc(100vh - ${offsetTop + 55}px)`,
        }}
        ref={dataGridContainerRef}
      >
        <DataGrid
          treeData
          apiRef={apiRef}
          rows={tableRows}
          columns={columns}
          getTreeDataPath={getTreeDataPath}
          groupingColDef={groupingColumnRender}
          pinnedRows={pinnedRows}
          initialState={{
            pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD], right: ['total'] },
          }}
          disableChildrenFiltering
          hideFooter
          hideFooterRowCount
          disableRowSelectionOnClick
          isGroupExpandedByDefault={isGroupExpandedByDefault}
          loading={isLoading}
          disableVirtualization
          sx={{
            '& .MuiDataGrid-virtualScrollerContent': {
              minHeight: 'auto !important',
            },
            '.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
              {
                outline: 'none !important',
              },
            '& .MuiDataGrid-cell': {
              overflow: 'visible !important',
            },
            // Temp fix for the grouping cell width, MUI already deployed but on v7
            '& .MuiDataGrid-treeDataGroupingCell': {
              width: 'unset',
            },
            '& .MuiDataGrid-groupingCriteriaCell': {
              width: 'unset',
            },
          }}
          autosizeOnMount
          autosizeOptions={{ includeOutliers: true, includeHeaders: true, expand: true }}
          slots={{
            columnMenu: CustomColumnMenu,
          }}
          disableColumnTopBorder
          data-automation-id="ResourceViewEmployeeTable"
        />
      </div>
    );
  },
  (prevProp, nextProp) => {
    const isSameViewOptions =
      prevProp.selectedViewOptions['period-starts-at'] ===
        nextProp.selectedViewOptions['period-starts-at'] &&
      prevProp.selectedViewOptions['period-ends-at'] ===
        nextProp.selectedViewOptions['period-ends-at'] &&
      prevProp.selectedViewOptions['unit-types'] === nextProp.selectedViewOptions['unit-types'] &&
      prevProp.selectedViewOptions['reporting-types'] ===
        nextProp.selectedViewOptions['reporting-types'] &&
      prevProp.selectedViewOptions['period-types'] === nextProp.selectedViewOptions['period-types'];

    return isSameViewOptions;
  },
);
