export default {
  Model: {
    class: 'plan',
    properties: {
      tenantId: '10002',
      responseType: 'GroupByResource',
      viewOptions: {
        'grouped-by': 'group-by-resource',
        'reporting-types': 'utilization',
        'period-types': 'month',
        'unit-types': 'hours',
        'period-starts-at': '2022-11-09',
        'period-ends-at': '2023-04-30',
      },
      periods: [
        {
          startsAt: '2022-11-01T00:00:00',
          endsAt: '2022-11-30T00:00:00',
          type: 'Month',
          identifier: '2022Nov',
        },
        {
          startsAt: '2022-12-01T00:00:00',
          endsAt: '2022-12-31T00:00:00',
          type: 'Month',
          identifier: '2022Dec',
        },
        {
          startsAt: '2023-01-01T00:00:00',
          endsAt: '2023-01-31T00:00:00',
          type: 'Month',
          identifier: '2023Jan',
        },
        {
          startsAt: '2023-02-01T00:00:00',
          endsAt: '2023-02-28T00:00:00',
          type: 'Month',
          identifier: '2023Feb',
        },
        {
          startsAt: '2023-03-01T00:00:00',
          endsAt: '2023-03-31T00:00:00',
          type: 'Month',
          identifier: '2023Mar',
        },
        {
          startsAt: '2023-04-01T00:00:00',
          endsAt: '2023-04-30T00:00:00',
          type: 'Month',
          identifier: '2023Apr',
        },
      ],
      children: [
        {
          StartsAt: '2022-08-15T00:00:00',
          EndsAt: '2022-09-05T00:00:00',
          hierarchyType: 'workitem',
          values: {
            '2022Nov': { displayValue: 0, heatmapCode: '', editable: true, value: 1 },
            '2022Dec': { displayValue: 8.0, heatmapCode: '', editable: true, value: 1 },
            '2023Jan': { displayValue: 0, heatmapCode: '', editable: true, value: 1 },
            '2023Feb': { displayValue: 0, heatmapCode: '', editable: true, value: 1 },
            '2023Mar': { displayValue: 0, heatmapCode: '', editable: true, value: 1 },
            '2023Apr': { displayValue: 0, heatmapCode: '', editable: true, value: 1 },
          },
          children: [
            {
              StartsAt: '2022-08-15T00:00:00',
              EndsAt: '2022-09-05T00:00:00',
              hierarchyType: 'workitem',
              values: {
                '2022Nov': {
                  displayValue: 7.6,
                  heatmapCode: 'bgAvailability65',
                  editable: true,
                  value: 1,
                },
                '2022Dec': {
                  displayValue: 0,
                  heatmapCode: 'bgAvailability60',
                  editable: true,
                  value: 1,
                },
                '2023Jan': {
                  displayValue: 0,
                  heatmapCode: 'bgAvailability55',
                  editable: true,
                  value: 1,
                },
                '2023Feb': {
                  displayValue: 0,
                  heatmapCode: 'bgAvailability50',
                  editable: true,
                  value: 1,
                },
                '2023Mar': {
                  displayValue: 0,
                  heatmapCode: 'bgAvailability45',
                  editable: true,
                  value: 1,
                },
                '2023Apr': {
                  displayValue: 0,
                  heatmapCode: 'bgAvailability40',
                  editable: true,
                  value: 1,
                },
              },
              Budget: 0,
              TotalBooked: 7.6,
              TotalActualWorkload: 0.0,
              TotalEAC: 7.6,
              total: { displayValue: 7.6 },
              canExpand: true,
              editable: false,
              canAssign: false,
              canDelete: false,
              resourceId: '90462099863502848',
              resourceSourceReferenceId: 1,
              resourceType: 'Employee',
              workItemId: '92437482984964081',
              workItemSourceReferenceId: '488',
              workItemType: 'Task',
              id: '92437482984964081',
              name: 'Desmond2',
              sourceReferenceId: '488',
              tenantId: '10002',
              parentId: '92437481768615936',
              type: 'Task',
              pathToParent: '/92437481768615936/',
              sortOrder: 2,
              level: 2,
              sortablePath: '/Anson has a Tree/002',
              isActive: false,
              startAt: '0001-01-01T00:00:00',
              endAt: '0001-01-01T00:00:00',
              status: 'InProgress',
            },
          ],
          Budget: 0,
          TotalBooked: 8.0,
          TotalActualWorkload: 0.0,
          TotalEAC: 8.0,
          total: { displayValue: 8.0 },
          canExpand: true,
          editable: true,
          canAssign: false,
          canDelete: true,
          resourceId: '90462099863502847',
          resourceSourceReferenceId: 1,
          resourceType: 'Employee',
          workItemId: '92437482376790016',
          workItemSourceReferenceId: '487',
          workItemType: 'Task',
          id: '92437482376790016',
          name: 'FPSA001',
          sourceReferenceId: '487',
          tenantId: '10002',
          parentId: '92437481768615936',
          type: 'Task',
          pathToParent: '/92437481768615936/',
          sortOrder: 1,
          level: 2,
          sortablePath: '/Anson has a Tree/001',
          isActive: false,
          startAt: '0001-01-01T00:00:00',
          endAt: '0001-01-01T00:00:00',
          status: 'InProgress',
          notPlanned: 1,
        },
        // {
        //   StartsAt: '2022-08-15T00:00:00',
        //   EndsAt: '2022-09-05T00:00:00',
        //   hierarchyType: 'workitem',
        //   values: {
        //     '2022Nov': {
        //       displayValue: 7.6,
        //       heatmapCode: 'bgAvailability65',
        //       editable: true,
        //       value: 1,
        //     },
        //     '2022Dec': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability60',
        //       editable: true,
        //       value: 1,
        //     },
        //     '2023Jan': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability55',
        //       editable: true,
        //       value: 1,
        //     },
        //     '2023Feb': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability50',
        //       editable: true,
        //       value: 1,
        //     },
        //     '2023Mar': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability45',
        //       editable: true,
        //       value: 1,
        //     },
        //     '2023Apr': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability40',
        //       editable: true,
        //       value: 1,
        //     },
        //   },
        //   Budget: 0,
        //   TotalBooked: 7.6,
        //   TotalActualWorkload: 0.0,
        //   TotalEAC: 7.6,
        //   total: { displayValue: 7.6 },
        //   canExpand: true,
        //   editable: false,
        //   canAssign: false,
        //   canDelete: false,
        //   resourceId: '90462099863502848',
        //   resourceSourceReferenceId: 1,
        //   resourceType: 'Employee',
        //   workItemId: '92437482984964096',
        //   workItemSourceReferenceId: '488',
        //   workItemType: 'Task',
        //   id: '92437482984964096',
        //   name: 'FPSA002',
        //   sourceReferenceId: '488',
        //   tenantId: '10002',
        //   parentId: '92437481768615936',
        //   type: 'Task',
        //   pathToParent: '/92437481768615936/',
        //   sortOrder: 2,
        //   level: 2,
        //   sortablePath: '/Anson has a Tree/002',
        //   isActive: false,
        //   startAt: '0001-01-01T00:00:00',
        //   endAt: '0001-01-01T00:00:00',
        //   status: 'InProgress',
        // },
        // {
        //   StartsAt: '2022-08-20T00:00:00',
        //   EndsAt: '2022-09-20T00:00:00',
        //   hierarchyType: 'workitem',
        //   values: {
        //     '2022Nov': {
        //       displayValue: 7.6,
        //       heatmapCode: 'bgAvailability65',
        //       editable: true,
        //       value: 1,
        //     },
        //     '2022Dec': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability60',
        //       editable: true,
        //       value: 2,
        //     },
        //     '2023Jan': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability55',
        //       editable: true,
        //       value: 3,
        //     },
        //     '2023Feb': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability50',
        //       editable: true,
        //       value: 4,
        //     },
        //     '2023Mar': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability45',
        //       editable: true,
        //       value: 5,
        //     },
        //     '2023Apr': {
        //       displayValue: 0,
        //       heatmapCode: 'bgAvailability40',
        //       editable: true,
        //       value: 6,
        //     },
        //   },
        //   Budget: 0,
        //   TotalBooked: 7.6,
        //   TotalActualWorkload: 0.0,
        //   TotalEAC: 7.6,
        //   total: { displayValue: 7.6 },
        //   canExpand: false,
        //   editable: false,
        //   canAssign: false,
        //   canDelete: false,
        //   resourceId: '90462099863502849',
        //   resourceSourceReferenceId: 1,
        //   resourceType: 'Employee',
        //   workItemId: '92437482984964097',
        //   workItemSourceReferenceId: '488',
        //   workItemType: 'Task',
        //   id: '92437482984964099',
        //   name: 'FPSA003',
        //   sourceReferenceId: '488',
        //   tenantId: '10002',
        //   parentId: '92437481768615936',
        //   type: 'Project',
        //   pathToParent: '/92437481768615936/',
        //   sortOrder: 2,
        //   level: 2,
        //   sortablePath: '/Anson has a Tree/003',
        //   isActive: false,
        //   startAt: '0001-01-01T00:00:00',
        //   endAt: '0001-01-01T00:00:00',
        //   status: 'InProgress',
        // },
      ],
    },
    links: [
      {
        href: '/plan/partialgroupbyemployee?periodstartsat=2023-12-07&periodendsat=2023-12-31&periodtypes=week&unittypes=hours&reportingtypes=availability&showtaskno=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 620272908,
  StatusCode: 200,
};
