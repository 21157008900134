export default {
  Model: {
    class: 'plantotalrowvalues',
    properties: {
      tenantId: '35711',
      responseType: 'GroupByResource',
      row: {
        total: {
          displayValue: 50.08,
          value: 50.08,
        },
        values: {
          '2022Nov': {
            displayValue: 9.52,
            value: 9.52,
            heatmapCode: 'bgAvailability65',
            editable: false,
          },
          '2022Dec': {
            displayValue: 14.28,
            value: 14.28,
            heatmapCode: 'bgAvailability65',
            editable: false,
          },
          '2023Jan': {
            displayValue: 11.68,
            value: 11.68,
            heatmapCode: 'bgAvailability40',
            editable: false,
          },
          '2023Feb': {
            displayValue: 14.6,
            value: 14.6,
            heatmapCode: 'bgAvailability40',
            editable: false,
          },
          '2023Mar': {
            displayValue: 14.76,
            value: 14.76,
            heatmapCode: 'bgAvailability45',
            editable: false,
          },
          '2023Apr': {
            displayValue: 15.6,
            value: 15.6,
            heatmapCode: 'bgAvailability45',
            editable: false,
          },
        },
        TotalBudget: 1276,
        TotalBooked: 915.32,
        TotalActualWorkload: 340.35,
        TotalEAC: 1255.67,
        TotalNotPlanned: 20.33,
      },
    },
    links: [
      {
        href: 'http://resourceplanner.aks.timelog.com:80/plan/groupbyemployeetotalrowvalues?periodstartsat=2023-12-21&periodendsat=2024-01-14&periodtypes=week&unittypes=hours&reportingtypes=availability&resourceids=72312281756598272&showtaskno=true',
        rel: 'self',
      },
    ],
  },
  DeterministicHash: 1042814499,
  StatusCode: 200,
};
