import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import postResyncDataOnAllocation from 'src/apis/resourcePlannerAPI/post/postResyncDataOnAllocation';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, IconButton, Menu, MenuItem, Typography } from 'src/components/mui-components';
import { formatTime } from 'src/utils/time';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { DistributeUnplannedWorkloadModalContentMui } from '../DistributeUnplannedWorkloadModalContentMui';

export const UnplannedMuiCell = (params: GridRenderCellParams) => {
  const { row } = params;
  const {
    notPlanned,
    hierarchyType,
    resourceId,
    startsAt,
    endsAt,
    negativeUnplannedHours,
    type,
    hasChildWithEndDateOnTodayOrAfter,
    workItemSourceReferenceId,
    workItemType,
    resourceSourceReferenceId,
    id,
  } = row;

  const { t } = useTranslation('resourcePlanner');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState(false);

  const workItemId = type?.toLowerCase() === 'employee' ? '' : row.id;
  const resourceName = hierarchyType === 'resource' ? row.name : row?.rootParentName ?? '';
  const workItemName = hierarchyType === 'resource' ? '' : row.name;
  const isLowestLevel = row.children === undefined || row.children?.length < 1;

  const { mutate: postResyncData } = postResyncDataOnAllocation();

  const siteLocale = useGetLocale();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
    handleClose();
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const onSyncPlan = () => {
    if (workItemSourceReferenceId && workItemType && resourceSourceReferenceId) {
      postResyncData({
        workItemSourceReferenceId,
        workItemType,
        resourceSourceReferenceId,
      });
    }
    handleClose();
  };

  const isResourceRow = hierarchyType?.toLowerCase() === 'resource';

  const rowType = type?.toLowerCase();

  const headingText = isResourceRow ? (
    <Trans
      i18nKey="ModalDistributeUnplannedResourceHeader"
      key="ModalDistributeUnplannedResourceHeader"
      defaults={t('ModalDistributeUnplannedResourceHeader')}
      values={{ resourceName }}
      components={[
        <Typography fontWeight={600} fontSize="inherit" component="span">
          {resourceName}
        </Typography>,
      ]}
    />
  ) : (
    <Trans
      i18nKey="ModalDistributeUnplannedResourceAndWorkItemHeader"
      key="ModalDistributeUnplannedResourceAndWorkItemHeader"
      defaults={t('ModalDistributeUnplannedResourceAndWorkItemHeader')}
      values={{ resourceName, workItemName }}
      components={[
        <Typography fontWeight={600} fontSize="inherit" component="span">
          {resourceName}
        </Typography>,
        <Typography fontWeight={600} fontSize="inherit" component="span">
          {workItemName}
        </Typography>,
      ]}
    />
  );
  return (
    <>
      {id !== 'total' && (
        <Box display="flex" flexGrow={1}>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'smart-plan-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{ marginLeft: '-10px' }}
            title="Show more"
            data-automation-id="unplanned-menu"
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="smart-plan-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem
              key={`unplanned-cell-${resourceId}-${workItemId}`}
              onClick={handleModalOpen}
              data-automation-id="distribute-unplanned-work-modal"
            >
              {t('ContextMenuDistributeUnplannedWork')}
            </MenuItem>
            <MenuItem
              key={`unplanned-cell-${resourceId}-${workItemId}-resync`}
              onClick={onSyncPlan}
              disabled={
                !workItemSourceReferenceId ||
                !workItemType ||
                workItemType === 'Unknown' ||
                !resourceSourceReferenceId
              }
            >
              {t('ContextMenuResyncProjectPlan')}
            </MenuItem>
          </Menu>
        </Box>
      )}

      {notPlanned ? formatTime(notPlanned, siteLocale) : formatTime('0', siteLocale)}

      <DistributeUnplannedWorkloadModalContentMui
        isResourceRow={isResourceRow}
        resourceId={resourceId}
        workItemId={workItemId}
        isLowestLevel={isLowestLevel}
        startsAt={startsAt}
        endsAt={endsAt}
        negativeUnplannedHours={negativeUnplannedHours}
        rowType={rowType}
        hasChildWithEndDateOnTodayOrAfter={hasChildWithEndDateOnTodayOrAfter}
        headingText={headingText}
        open={modalOpen}
        onClose={handleModalClose}
      />
    </>
  );

  return null;
};
