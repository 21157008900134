import { SearchInput, Shimmer, Icon } from 'src/components/ui-components';
import { DateTime } from 'luxon';
import { useState, ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { usePostCreateWorkItemRelationship } from 'src/apis/resourcePlannerAPI';
import { ISearchTask } from 'src/apis/types/resourcePlannerAPI';
import { useGetSearchTaskByNameAPI } from 'src/apis/resourcePlannerAPI/get/getSearchTaskByNameAPI';
import { useDebounce } from 'use-debounce';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { Button, Box, Stack, Typography } from 'src/components/mui-components';
import { AssignAction } from '../AssignAction';
import { AssignItem } from '../AssignItem';
import { ScrollContainer } from '../ScrollContainer';
import { AssignButton } from '../AssignButton';
import { AssignmentSelectorFittedProps } from '../../types';

export const TaskSelector = ({
  itemId,
  itemSourceReferenceId,
  itemType,
  itemTitle,
  ...props
}: AssignmentSelectorFittedProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [debouncedInputValue] = useDebounce(searchValue, 100);
  const [selectedStartDate] = useState<DateTime | null>(null);
  const [selectedEndDate] = useState<DateTime | null>(null);
  const [totalHours] = useState('');
  const [selectedTask, setSelectedTask] = useState<ISearchTask>();
  const { t } = useTranslation('resourcePlanner');
  const { isInitialLoading, isError, isSuccess, workItems } = useGetSearchTaskByNameAPI(
    itemId,
    debouncedInputValue,
  );
  const searchInputElement = useRef<HTMLInputElement>(null);
  const changeButton = useRef<HTMLButtonElement>(null);

  const reset = () => {
    setSearchValue('');
    setSelectedTask(undefined);
    setModalIsOpen(false);
  };

  const select = (item: ISearchTask) => {
    setSelectedTask(item);

    // this is to bring focus to the view where you can see
    // the chosen task. Otherwise Escape wouldn't work
    setTimeout(() => changeButton.current?.focus(), 100);
  };

  const { mutate: postAssignTaskToResource } = usePostCreateWorkItemRelationship();
  const doAssign = () => {
    if (selectedTask) {
      postAssignTaskToResource({
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        totalHours,
        resourceId: itemId,
        resourceSourceReferenceId: itemSourceReferenceId,
        resourceType: itemType,
        workItemId: selectedTask?.id,
        workItemSourceReferenceId: selectedTask?.sourceReferenceId,
        workItemType: selectedTask?.type,
      });
      reset();
    }
  };

  return (
    <AssignAction
      {...props}
      heading={
        <>
          <Typography component="span" variant="h2">
            {t('Assign')}
          </Typography>{' '}
          <Typography component="span" variant="h2" color="primary.main">
            <Box component="span" fontWeight="fontWeightMedium">
              {itemTitle}
            </Box>
          </Typography>{' '}
          {selectedTask && (
            <Typography component="span" variant="h2">
              to
            </Typography>
          )}
        </>
      }
      isOpen={modalIsOpen}
      setIsOpen={setModalIsOpen}
      onDismiss={reset}
      footerComponent={
        selectedTask && (
          <>
            <Button onClick={reset}>{t('Cancel')}</Button>
            <Button onClick={doAssign} autoFocus>
              {t('Assign')}
            </Button>
          </>
        )
      }
    >
      {selectedTask ? (
        <Stack>
          <AssignItem
            mainText={selectedTask.taskName}
            secondaryText={selectedTask.customerName}
            tertiaryText={selectedTask.projectName}
            type={selectedTask.type}
          />
          <Button
            sx={{ width: 'fit-content' }} // The flex in stack will cause the button to stretched to full width
            onClick={() => setSelectedTask(undefined)}
            ref={changeButton}
          >
            {t('ChangeTask')}
          </Button>
        </Stack>
      ) : (
        <Stack sx={{ mt: 1 }}>
          <SearchInput
            clearInput={() => setSearchValue('')}
            ref={searchInputElement}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            value={searchValue}
            clearInputText=""
            label={t('SearchATaskOrProduct')}
            hiddenLabel
            placeholder={t('SearchATaskOrProduct')}
            autoComplete="off"
          />
          <Stack direction="row" alignItems="center" gap={1}>
            <Icon iconName="info" />
            <Typography>{t('SplitSearchHelpText')}</Typography>
          </Stack>
          {searchValue && (
            <ResponseHandler
              isLoading={isInitialLoading}
              isError={isError}
              isEmpty={isSuccess && workItems.length === 0}
              LoadingComponent={
                <ScrollContainer>
                  <Stack>
                    <div>
                      <Shimmer minHeight="subHeading" width="quarter" />
                    </div>
                    <Shimmer minHeight="body" width="thirdQuarter" />
                  </Stack>
                </ScrollContainer>
              }
              ErrorComponent={
                <ScrollContainer>
                  <Typography>{t('SearchATaskErrorMessage')}</Typography>
                </ScrollContainer>
              }
              EmptyComponent={
                <ScrollContainer>
                  <Typography>{t('SearchATaskNoResultsMessage')}</Typography>
                </ScrollContainer>
              }
            >
              <ScrollContainer>
                {workItems.map((workItem: ISearchTask) => (
                  <AssignButton onClick={() => select(workItem)} key={workItem.id}>
                    <AssignItem
                      mainText={workItem.taskName}
                      secondaryText={workItem.customerName}
                      tertiaryText={workItem.projectName}
                      type={workItem.type}
                      searchText={searchValue}
                    />
                  </AssignButton>
                ))}
              </ScrollContainer>
            </ResponseHandler>
          )}
        </Stack>
      )}
    </AssignAction>
  );
};
